<main id="main-content" class="tw-w-full tw-h-full">
  <div class="tw-flex tw-flex-col tw-items-center tw-w-full">
    <div
      class="tw-flex tw-flex-col tw-shadow-md tw-w-full tw-items-center tw-self-center">
      <div class="tw-w-full">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>
