import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AppState,
  AuthService,
  RedirectLoginOptions,
} from '@auth0/auth0-angular';
import { Language } from '@core/services/language.service';

@Injectable({
  providedIn: 'root',
})
export class VaninAuthorizeService {
  constructor(private _authService: AuthService) {}

  login(lang?: Language): Observable<void> {
    const options: RedirectLoginOptions<AppState> = {
      authorizationParams: {
        language: lang?.loginCode,
      },
    };
    return this._authService.loginWithRedirect(options);
  }
  register(lang?: Language): Observable<void> {
    const options: RedirectLoginOptions<AppState> = {
      authorizationParams: {
        action: 'signup',
        language: lang?.loginCode,
      },
    };
    return this._authService.loginWithRedirect(options);
  }
}
