// .-- angular --
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, concatMap, of, throwError } from 'rxjs';

// .-- app --
import { ApiLandingPageInfo } from './api-landing-page.interface';
import { EnvironmentInfo } from './environment-interface';
import { ApiLandingPageImage } from './api-landing-page-image.interface';
import { environment } from '../../environments/environment';
import { DUTCH } from '@core/services/language.service';

const PATH = '/landing-page';
@Injectable({
  providedIn: 'root',
})
export class ApiLandingPageService {
  constructor(private _http: HttpClient) {}

  baseUrl = environment.baseUrl;

  getLandingPageInfo(
    params?: ApiLandingPageInfo.Get.Request.Params
  ): Observable<ApiLandingPageInfo.Get.Response.Body> {
    const url = `${this.baseUrl}${PATH}`;
    params = params || { lang: DUTCH.code };
    const headers = new HttpHeaders({
      rejectUnauthorized: 'false',
    });

    return this._http
      .get<ApiLandingPageInfo.Get.Response.Body>(url, { params, headers })
      .pipe(catchError(throwError));
  }

  postLandingPageImage(
    formData: FormData
  ): Observable<ApiLandingPageImage.Post.Response.Body> {
    const url = `${this.baseUrl}${PATH}/editor/image/upload`;

    return this.requestToken().pipe(
      concatMap((token: string) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
          rejectUnauthorized: 'false',
        });
        return this._http
          .post<ApiLandingPageImage.Post.Response.Body>(url, formData, {
            headers,
          })
          .pipe(catchError(throwError));
      })
    );
  }

  private requestToken(): Observable<string> {
    const credentials = { username: 'username', password: 'password' };
    const headers = new HttpHeaders({
      rejectUnauthorized: 'false',
    });
    return this._http
      .post(`${this.baseUrl}/auth`, credentials, { headers })
      .pipe(
        concatMap((response: any) => {
          const token = response.access_token;
          return of(token);
        })
      );
  }

  putLandingPageInfo(
    landingPageInfo: ApiLandingPageInfo.Put.Request.Body
  ): Observable<ApiLandingPageInfo.Put.Response.Body> {
    const url = `${this.baseUrl}${PATH}/editor`;

    return this.requestToken().pipe(
      concatMap((token: string) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
          rejectUnauthorized: 'false',
        });
        return this._http
          .put<ApiLandingPageInfo.Put.Response.Body>(url, landingPageInfo, {
            headers,
          })
          .pipe(catchError(throwError));
      })
    );
  }

  getLandingPageInfoJSON(): Observable<ApiLandingPageInfo.Get.Response.Body> {
    return this._http
      .get<ApiLandingPageInfo.Get.Response.Body>(
        '/jsons/get-landing-page-info.json'
      )
      .pipe(catchError(throwError));
  }

  getEnvironmentInfoJSON(): Observable<EnvironmentInfo.Get.Response.Body> {
    return this._http
      .get<EnvironmentInfo.Get.Response.Body>('/jsons/env.json')
      .pipe(catchError(throwError));
  }
}
