import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';

import { ModalState } from './modal.model';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private state$ = new BehaviorSubject<ModalState | undefined>(undefined);
  private portal$ = new BehaviorSubject<TemplatePortal | undefined>(undefined);

  get state(): Observable<ModalState | undefined> {
    return this.state$.asObservable();
  }

  get portal(): Observable<TemplatePortal | undefined> {
    return this.portal$.asObservable();
  }

  open() {
    this.state$.next({ open: true });
  }

  close() {
    this.state$.next({ open: false });
  }

  setModalPortal(portal: TemplatePortal) {
    this.portal$.next(portal);
  }
}
