// .-- angular --
import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';

// .-- app --
import { ApiLandingPageService } from 'src/app/api/landing-page/api-landing-page.service';
import { LandingPageInfo } from './landing-page.interface';
import { ApiLandingPageInfo } from 'src/app/api/landing-page/api-landing-page.interface';
import { DUTCH } from '@core/services/language.service';

@Injectable({
  providedIn: 'root',
})
export class LandingPageService {
  constructor(private _apiLandingPageService: ApiLandingPageService) {}

  imageUrl: string = '';

  deleteArticleCard$ = new Subject<number>();
  get deleteArticleCard(): Observable<number> {
    return this.deleteArticleCard$.asObservable();
  }
  deleteArticleCardImage$ = new Subject<number>();
  get deleteArticleCardImage(): Observable<number> {
    return this.deleteArticleCardImage$.asObservable();
  }

  editTextModal$ = new Subject<{ path: string; index?: number }>();

  get editTextModal(): Observable<{ path: string; index?: number }> {
    return this.editTextModal$.asObservable();
  }

  editLinkModal$ = new Subject<{
    linkTextPath: string;
    linkUrlPath: string;
    index?: number;
  }>();

  get editLinkModal(): Observable<{
    linkTextPath: string;
    linkUrlPath: string;
    index?: number;
  }> {
    return this.editLinkModal$.asObservable();
  }

  editImageModal$ = new Subject<{ path: string; index?: number }>();

  get editImageModal(): Observable<{ path: string; index?: number }> {
    return this.editImageModal$.asObservable();
  }

  postLandingPageImage(image: File) {
    const formData = new FormData();
    formData.append('image', image);

    return this._apiLandingPageService.postLandingPageImage(formData);
  }

  getLandingPageInfo(lang?: string): Observable<LandingPageInfo> {
    return this._apiLandingPageService.getLandingPageInfo({ lang }).pipe(
      map(result => {
        const infoCardArray = result.content.infoCard.map(infoCard => {
          return {
            title: infoCard.title,
            description: infoCard.description,
            imageUrl: infoCard.imageUrl || this.imageUrl,
            imageAlt: infoCard.imageAlt,
            redirectionUrl: infoCard.redirectionUrl,
          };
        });

        const articleCardArray = result.content.articleCard.map(articleCard => {
          return {
            title: articleCard.title,
            description: articleCard.description,
            readMoreText: articleCard.readMoreText,
            readMoreUrl: articleCard.readMoreUrl,
            imageUrl: articleCard.imageUrl || this.imageUrl,
          };
        });

        const footerElementArray = result.content.footer.element.map(
          footerElement => {
            return {
              text: footerElement.text,
              imageUrl: footerElement.imageUrl || this.imageUrl,
              imageAlt: footerElement.imageAlt,
              redirectionUrl: footerElement.redirectionUrl,
            };
          }
        );

        return {
          banner: {
            title: result.content.banner.title,
            description: result.content.banner.description,
            list: result.content.banner.list,
            buttonText: result.content.banner.buttonText,
            registerText: result.content.banner.registerText,
          },
          infoCard: infoCardArray,
          articleTitle: result.content.articleTitle,
          articleCard: articleCardArray,
          registerBanner: {
            title: result.content.registerBanner.title,
            description: result.content.registerBanner.description,
            buttonText: result.content.registerBanner.buttonText,
            loginText: result.content.registerBanner.loginText,
          },
          footer: { element: footerElementArray },
          version: result.version,
        } as LandingPageInfo;
      })
    );
  }

  setLandingPageInfo(
    landingPageInfo: LandingPageInfo,
    version: number,
    lang: string = DUTCH.code
  ): Observable<ApiLandingPageInfo.Put.Response.Body> {
    const footerElements = landingPageInfo.footer.element.map(footerElement => {
      return {
        text: footerElement.text,
        imageUrl: footerElement.imageUrl,
        imageAlt: footerElement.imageAlt,
        redirectionUrl: footerElement.redirectionUrl,
      };
    });
    const articleCards = landingPageInfo.articleCard.map(articleCard => {
      return {
        title: articleCard.title,
        description: articleCard.description,
        readMoreText: articleCard.readMoreText,
        readMoreUrl: articleCard.readMoreUrl,
        imageUrl: articleCard.imageUrl,
      };
    });

    const landingPageInfoAPI: ApiLandingPageInfo.Put.Request.Body = {
      user: 'devUser',
      data: {
        content: {
          banner: {
            title: landingPageInfo.banner.title,
            description: landingPageInfo.banner.description,
            list: [
              landingPageInfo.banner.list[0],
              landingPageInfo.banner.list[1],
              landingPageInfo.banner.list[2],
            ],
            buttonText: landingPageInfo.banner.buttonText,
            registerText: landingPageInfo.banner.registerText,
          },
          infoCard: [
            {
              title: landingPageInfo.infoCard[0].title,
              description: landingPageInfo.infoCard[0].description,
              imageUrl: landingPageInfo.infoCard[0].imageUrl,
              imageAlt: landingPageInfo.infoCard[0].imageAlt,
              redirectionUrl: landingPageInfo.infoCard[0].redirectionUrl,
            },
            {
              title: landingPageInfo.infoCard[1].title,
              description: landingPageInfo.infoCard[1].description,
              imageUrl: landingPageInfo.infoCard[1].imageUrl,
              imageAlt: landingPageInfo.infoCard[1].imageAlt,
              redirectionUrl: landingPageInfo.infoCard[1].redirectionUrl,
            },
          ],
          articleTitle: landingPageInfo.articleTitle,
          articleCard: articleCards,
          registerBanner: {
            title: landingPageInfo.registerBanner.title,
            description: landingPageInfo.registerBanner.description,
            buttonText: landingPageInfo.registerBanner.buttonText,
            loginText: landingPageInfo.registerBanner.loginText,
          },
          footer: {
            element: footerElements,
          },
        },

        editedBy: 'sanoma',
        version: version + 1 || landingPageInfo.version + 1,
        lang,
      },
      priorVersion: landingPageInfo.version,
      lastEditedDate: '',
      lang,
    };

    return this._apiLandingPageService.putLandingPageInfo(landingPageInfoAPI);
  }

  getLandingPageInfoJSON(): Observable<LandingPageInfo> {
    const landingPageInfo: Observable<LandingPageInfo> =
      this._apiLandingPageService.getLandingPageInfoJSON().pipe(
        map(result => {
          const infoCardArray = result.content.infoCard.map(infoCard => {
            return {
              title: infoCard.title,
              description: infoCard.description,
              imageUrl: infoCard.imageUrl || this.imageUrl,
              imageAlt: infoCard.imageAlt,
              redirectionUrl: infoCard.redirectionUrl,
            };
          });

          const articleCardArray = result.content.articleCard.map(
            articleCard => {
              return {
                title: articleCard.title,
                description: articleCard.description,
                readMoreText: articleCard.readMoreText,
                readMoreUrl: articleCard.readMoreUrl,
                imageUrl: articleCard.imageUrl || this.imageUrl,
              };
            }
          );

          const footerElementArray = result.content.footer.element.map(
            footerElement => {
              return {
                text: footerElement.text,
                imageUrl: footerElement.imageUrl || this.imageUrl,
                imageAlt: footerElement.imageAlt,
                redirectionUrl: footerElement.redirectionUrl,
              };
            }
          );

          return {
            banner: {
              title: result.content.banner.title,
              description: result.content.banner.description,
              list: result.content.banner.list,
              buttonText: result.content.banner.buttonText,
              registerText: result.content.banner.registerText,
            },
            infoCard: infoCardArray,
            articleTitle: result.content.articleTitle,
            articleCard: articleCardArray,
            registerBanner: {
              title: result.content.registerBanner.title,
              description: result.content.registerBanner.description,
              buttonText: result.content.registerBanner.buttonText,
              loginText: result.content.registerBanner.loginText,
            },
            footer: { element: footerElementArray },
          } as LandingPageInfo;
        })
      );
    return landingPageInfo;
  }

  getPropertyByPath(obj: any, path: string, index?: number): any {
    const properties = path.split('.');
    let value = obj;

    for (const prop of properties) {
      value = value[prop];
      if (Array.isArray(value) && index !== undefined) {
        value = value[index];
      }
    }
    return value;
  }

  setPropertyByPath(
    obj: any,
    path: string,
    newValue: any,
    index?: number,
    isImage: boolean = false
  ) {
    if ((newValue && newValue !== '') || isImage) {
      const properties = path.split('.');
      const lastProp = properties.pop();

      let value = obj;
      for (const prop of properties) {
        value = value[prop];
        if (Array.isArray(value) && index !== undefined) {
          value = value[index];
        }
      }

      if (Array.isArray(value[lastProp!])) {
        value[lastProp!][index!] = newValue;
      } else {
        value[lastProp!] = newValue;
      }
    }
  }
}
