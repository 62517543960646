<div *ngIf="articleCardTexts" class="tw-shadow-md tw-rounded-lg tw-bg-white">
  <div
    class="tw-flex tw-flex-col md:tw-flex-row tw-content-between tw-items-center">
    <div
      *ngIf="articleCardTexts.imageUrl && imagePosition === 'left'"
      class="tw-bg-white tw-w-[200px] tw-h-[200px] tw-flex-shrink-0 tw-rounded-lg">
      <img
        class="tw-object-cover tw-w-full tw-h-full"
        [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
        width="200"
        height="200"
        viewBox="0 0 200 200"
        (click)="editImage()"
        [src]="articleCardTexts.imageUrl" />
    </div>
    <div
      *ngIf="articleCardTexts.imageUrl && imagePosition === 'right'"
      class="tw-block md:tw-hidden tw-bg-white tw-w-[200px] tw-h-[200px] tw-flex-shrink-0 tw-rounded-lg">
      <img
        class="tw-object-cover tw-w-full tw-h-full"
        [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
        (click)="editImage()"
        [src]="articleCardTexts.imageUrl" />
    </div>
    <div class="tw-flex tw-flex-1 tw-flex-col tw-gap-2 tw-m-8">
      <h5
        class="tw-font-bold tw-text-lg"
        [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
        (click)="editText('articleCard.title')"
        [innerHTML]="articleCardTexts.title"></h5>
      <div
        class="tw-overflow-hidden tw-leading-tight"
        [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
        (click)="editText('articleCard.description')"
        [innerHTML]="articleCardTexts.description"></div>
      <div
        class="tw-flex tw-flex-row tw-gap-3 tw-font-semibold kampus-color-purple-7">
        <div
          class="hover:tw-underline tw-cursor-pointer"
          (click)="readMore()"
          [innerHTML]="articleCardTexts.readMoreText"></div>
        <img
          class="tw-cursor-pointer"
          src="/icons/square-right.svg"
          alt="{{ 'ALT.IMAGE_ALT' | translate }}" />
      </div>
      <div
        *ngIf="isEditingUser"
        (click)="deleteArticleCard()"
        class="hover:tw-underline tw-cursor-pointer">
        {{ 'ARTICLE_CARD.DELETE_ARTICLE' | translate }}
      </div>
      <div
        *ngIf="isEditingUser && articleCardTexts.imageUrl"
        (click)="deleteArticleCardImage()"
        class="hover:tw-underline tw-cursor-pointer">
        {{ 'ARTICLE_CARD.DELETE_IMAGE' | translate }}
      </div>
      <div
        *ngIf="isEditingUser && !articleCardTexts.imageUrl"
        (click)="editImage()"
        class="hover:tw-underline tw-cursor-pointer">
        {{ 'ARTICLE_CARD.ADD_IMAGE' | translate }}
      </div>
    </div>
    <div
      *ngIf="articleCardTexts.imageUrl && imagePosition === 'right'"
      class="tw-hidden md:tw-block tw-bg-white tw-w-[200px] tw-h-[200px] tw-flex-shrink-0 tw-rounded-lg">
      <img
        class="tw-object-cover tw-w-full tw-h-full"
        [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
        (click)="editImage()"
        [src]="articleCardTexts.imageUrl" />
    </div>
  </div>
</div>
