import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export class Language {
  constructor(
    public code: string,
    public loginCode: string,
    public redirect: string,
    public translationKey: string
  ) {}
}

// .-- languages --
// .-- https://stackoverflow.com/questions/5580876/navigator-language-list-of-all-languages --
export const DUTCH = new Language('nl-be', 'nl_be', '', 'LANGUAGES.DUTCH');
export const FRENCH = new Language('fr-be', 'fr_be', '/fr', 'LANGUAGES.FRENCH');
export const ENGLISH = new Language(
  'en-en',
  'en_en',
  '/en',
  'LANGUAGES.ENGLISH'
);

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public langSelected!: Language;
  private langDefault: Language = DUTCH;
  private readonly allSupported = [DUTCH, FRENCH, ENGLISH];

  /**
   * Constructor: set default language
   */
  constructor(
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly activeRoute: ActivatedRoute
  ) {
    this.translateService = translateService;
    this.translateService.setDefaultLang(this.langDefault.code);
  }

  /**
   * Initialize a language, first checks navigator langaguage if not exits then has been setted in localStorage yet,
   * else set land by default.
   */
  public initialize(redirect?: boolean): void {
    const navigator = window.navigator;
    const localStorage = window.localStorage;
    let lang = this.langDefault;
    if (navigator?.language) {
      lang =
        this.getLanguageByCode(navigator.language.toLowerCase()) ||
        this.langDefault;
    } else if (localStorage && localStorage['language']) {
      lang =
        this.getLanguageByCode(localStorage['language']) || this.langDefault;
    }
    this.setLanguage(lang);
    if (redirect) {
      this.redirectTo(lang);
    }
  }

  /**
   * Get language selected
   */
  public getLanguage(): Language | undefined {
    return this.langSelected;
  }

  /**
   * Get language by code
   */
  public getLanguageByCode(code: string): Language | undefined {
    return this.allSupported.find(lang => lang.code === code);
  }

  /**
   * Get languages supported
   */
  public getLanguages() {
    return this.allSupported;
  }

  /**
   * Set a language passed by param
   * @param language: string
   */
  public setLanguage(language: Language) {
    if (localStorage) {
      localStorage['language'] = language.code;
    }
    this.langSelected = language;
    this.translateService.use(this.langSelected.code);
  }

  private redirectTo(language: Language) {
    this.router.navigate([language.redirect], { relativeTo: this.activeRoute });
  }
}
