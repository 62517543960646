import { environment } from './environments/environment';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { MainModule } from './pages/main/main.module';
import { SharedModule } from './@shared/share.module';
import { AuthModule } from '@auth0/auth0-angular';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

/* AOT configuration */
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'app/assets/i18n/', '.json');
}

import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    MainModule,
    SharedModule.forRoot(),
    AuthModule.forRoot({
      domain: environment.vaninLoginUrl,
      clientId: environment.clientId,
      authorizationParams: {
        redirect_uri: environment.loginRedirectUrl || window.location.origin,
        audience: 'https://sesame.sanomalearning.com',
      },
    }),
    // ToastrModule added
    ToastrModule.forRoot(),
    // Translate
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
