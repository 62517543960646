import { Component, Input } from '@angular/core';
import { InfoCard } from 'src/app/pages/landing-page/landing-page.interface';
import { LandingPageService } from 'src/app/pages/landing-page/landing-page.service';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
})
export class InfoCardComponent {
  @Input() infoCardTexts: InfoCard | undefined;
  @Input() isEditingUser: boolean = false;
  @Input() editingIndex?: number;

  constructor(
    private _landingPageService: LandingPageService,
  ) {}

  editText(path: string) {
    this._landingPageService.editTextModal$.next({
      path,
      index: this.editingIndex,
    });
  }
  editImage(path: string, index?: number) {
    this._landingPageService.editImageModal$.next({
      path,
      index: this.editingIndex,
    });
  }

  linkClicked(redirectionUrl: string) {
    if (this.isEditingUser) {
      this._landingPageService.editLinkModal$.next({
        linkTextPath: 'infoCard.description',
        linkUrlPath: 'infoCard.redirectionUrl',
        index: this.editingIndex!,
      });
    } else {
      window.open(redirectionUrl, '_blank');
    }
  }
}
