// .-- angular --
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CdkPortalOutlet,
  PortalOutlet,
  TemplatePortal,
  DomPortal,
} from '@angular/cdk/portal';
import { Renderer2 } from '@angular/core';
import { Subject, Subscription, take, takeUntil } from 'rxjs';

// .-- services --
import { ModalService } from './modal.service';
import { ModalState } from './modal.model';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  @ViewChild('modal', { static: false }) modal: ElementRef | undefined;
  @ViewChild(CdkPortalOutlet, { static: false }) portalOutlet:
    | PortalOutlet
    | undefined;

  title: string = '';

  domPortal: DomPortal<any> | undefined;
  isOpen = false;
  show = false;

  private destroy$ = new Subject();

  constructor(
    private modalService: ModalService,
    private _element: ElementRef,
    private _renderer: Renderer2
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.modalService.state.pipe(takeUntil(this.destroy$)).subscribe((state: ModalState | undefined) => {
        if (state) {
          if (state.open) {
            setTimeout(() => {
              this.open();
            });
          } else {
            setTimeout(() => {
              this.close();
            });
          }
        }
      })
    );
    this.subscriptions.push(
      this.modalService.portal.pipe(takeUntil(this.destroy$)).subscribe(
        (portal: TemplatePortal | undefined) => {
          if (portal) {
            this.isOpen = true;
            setTimeout(() => {
              this.portalOutlet?.detach();
              this.portalOutlet?.attach(portal);
            });
          }
        }
      )
    );
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  close(e?: Event) {
    this.show = false;
    setTimeout(() => {
      this.modalService.state.pipe(take(1)).subscribe(value => {
        if (value?.open) {
          this.modalService.close();
        }
      });

      this.isOpen = false;
    }, 200);
  }

  open() {
    this.isOpen = true;
    setTimeout(() => {
      this.show = true;
    });
  }

  protected showElement(): void {
    if (
      !this._element.nativeElement.parentNode ||
      this._element.nativeElement.parentNode.nodeType !== Node.ELEMENT_NODE
    ) {
      if (document && document.body) {
        document.body.appendChild(this._element.nativeElement);
      }
    }

    this._renderer.setAttribute(
      this._element.nativeElement,
      'aria-hidden',
      'false'
    );
    this._renderer.setAttribute(
      this._element.nativeElement,
      'aria-modal',
      'true'
    );
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.close();
  }
}
