<div
  *ngIf="registerBannerTexts"
  class="register-banner tw-text-white tw-flex tw-items-center tw-justify-center">
  <div class="tw-p-8 md:tw-p-14 md:tw-mr-[20%]">
    <h2
      class="tw-text-2xl tw-font-bold tw-py-2"
      [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
      (click)="editText('registerBanner.title')"
      [innerHTML]="registerBannerTexts.title"></h2>
    <div
      class="tw-text-base"
      [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
      (click)="editText('registerBanner.description')"
      [innerHTML]="registerBannerTexts.description">
      {{ registerBannerTexts.description }}
    </div>
    <button
      (click)="register()"
      type="button"
      class="btn btn-lg tw-bg-white hover:tw-bg-gray-200 tw-color-pink tw-font-semibold button tw-text-base tw-my-6 tw-px-8"
      [innerHTML]="registerBannerTexts.buttonText"></button>
    <div
      (click)="signIn()"
      class="tw-flex tw-flex-row tw-gap-1 tw-text-sm hover:tw-underline">
      <div
        class="tw-cursor-pointer"
        [innerHTML]="registerBannerTexts.loginText"></div>
    </div>
  </div>
</div>
