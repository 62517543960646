<div
  *ngIf="infoCardTexts"
  class="tw-relative tw-shadow-md tw-rounded-lg tw-w-full tw-bg-white tw-h-full">
  <div class="tw-p-8 tw-flex tw-flex-row tw-gap-5 tw-w-full tw-items-center">
    <img
      class="tw-hidden md:tw-block tw-w-14 tw-h-14"
      [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      (click)="editImage('infoCard.imageUrl')"
      [src]="infoCardTexts.imageUrl" />
    <img
      class="tw-block md:tw-hidden tw-w-10 tw-h-10"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      [src]="infoCardTexts.imageUrl" />
    <div class="tw-flex tw-flex-col tw-gap-3">
      <h5
        class="tw-text-lg tw-font-bold"
        (click)="editText('infoCard.title')"
        [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
        [innerHTML]="infoCardTexts.title"></h5>
      <div
        class="tw-flex tw-flex-row tw-align-middle tw-gap-2 kampus-color-purple-7">
        <div
          class="hover:tw-underline tw-cursor-pointer tw-font-semibold tw-text-sm"
          (click)="linkClicked(infoCardTexts.redirectionUrl)"
          [innerHTML]="infoCardTexts.description"></div>
        <img
          class="tw-cursor-pointer"
          src="/icons/square-right.svg"
          alt="{{ 'ALT.IMAGE_ALT' | translate }}" />
      </div>
    </div>
  </div>
</div>
