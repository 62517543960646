import { Component, Input } from '@angular/core';
import { ArticleCard } from 'src/app/pages/landing-page/landing-page.interface';
import { LandingPageService } from 'src/app/pages/landing-page/landing-page.service';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
})
export class ArticleCardComponent {
  @Input() articleCardTexts: ArticleCard | undefined;
  @Input() imagePosition: 'right' | 'left' = 'left';
  @Input() isEditingUser: boolean = false;
  @Input() editingIndex?: number;

  constructor(
    private _landingPageService: LandingPageService
  ) {}

  editText(path: string) {
    this._landingPageService.editTextModal$.next({
      path,
      index: this.editingIndex,
    });
  }

  editImage() {
    this._landingPageService.editImageModal$.next({
      path: 'articleCard.imageUrl',
      index: this.editingIndex,
    });
  }

  readMore() {
    if (this.isEditingUser) {
      this._landingPageService.editLinkModal$.next({
        linkTextPath: 'articleCard.readMoreText',
        linkUrlPath: 'articleCard.readMoreUrl',
        index: this.editingIndex!,
      });
    } else if (this.articleCardTexts?.readMoreUrl) {
      window.open(this.articleCardTexts?.readMoreUrl, '_blank');
    }
  }

  deleteArticleCard() {
    if (this.editingIndex !== undefined) {
      this._landingPageService.deleteArticleCard$.next(this.editingIndex);
    }
  }
  deleteArticleCardImage() {
    if (this.editingIndex !== undefined) {
      this._landingPageService.deleteArticleCardImage$.next(this.editingIndex);
    }
  }
}
