// .-- angular --
import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

// .-- app --
import { LandingPageService } from 'src/app/pages/landing-page/landing-page.service';
import { Banner } from 'src/app/pages/landing-page/landing-page.interface';
import { VaninAuthorizeService } from 'src/app/api/authorize/vanin-authorize.service';
import { DUTCH, Language } from '@core/services/language.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnDestroy {
  @Input() bannerTexts: Banner | undefined;
  @Input() isEditingUser: boolean = false;
  @Input() lang: Language = DUTCH;

  private destroy$ = new Subject();

  constructor(
    private _landingPageService: LandingPageService,
    private _vaninAuthorizeService: VaninAuthorizeService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  editText(path: string, index?: number) {
    this._landingPageService.editTextModal$.next({ path, index });
  }

  signIn() {
    if (this.isEditingUser) {
      this._landingPageService.editTextModal$.next({
        path: 'banner.buttonText',
      });
    } else {
      this._vaninAuthorizeService
        .login(this.lang)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {});
    }
  }
  register() {
    if (this.isEditingUser) {
      this._landingPageService.editTextModal$.next({
        path: 'banner.registerText',
      });
    } else {
      this._vaninAuthorizeService
        .register(this.lang)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {});
    }
  }
}
