// .-- angular --
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, take } from 'rxjs';

// .-- app --
import { LanguageService } from '@core/services/language.service';
import { EDIT_ROUTE, FRENCH_ROUTE, ENGLISH_ROUTE } from './app-routing.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private languageService: LanguageService,
    private router: Router
  ) {}

  title = 'kampus-landing-page-frontend';

  ngOnInit() {
    this.router.events
      .pipe(
        take(1), // only take the first event
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(event =>
        this.languageService.initialize(
          !(event as NavigationStart)?.url?.includes(EDIT_ROUTE) &&
            !(event as NavigationStart)?.url?.includes(FRENCH_ROUTE) &&
            !(event as NavigationStart)?.url?.includes(ENGLISH_ROUTE)
        )
      );
  }
}
