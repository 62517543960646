<div
  *ngIf="footerTexts"
  #footer
  id="footer"
  class="tw-py-4 footer tw-flex tw-justify-center tw-w-full">
  <div
    class="tw-flex md:tw-flex-row tw-flex-col md:tw-items-center md:tw-justify-between tw-items-start tw-gap-3 tw-font-bold tw-text-white tw-w-full tw-max-w-screen-lg tw-px-4">
    <div class="tw-rounded-[55px] tw-bg-white tw-p-[10px]">
      <img
        width="110"
        height="110"
        viewBox="0 0 110 110"
        src="/icons/van-in-logo.svg"
        alt="VanIn logo" />
    </div>

    <div
      *ngFor="let footerElement of footerTexts.element; index as i"
      class="hover:tw-underline tw-cursor-pointer tw-flex tw-justify-center tw-text-sm"
      (click)="elementClicked(footerElement.redirectionUrl, i)"
      [innerHTML]="footerElement.text"></div>
  </div>
</div>
