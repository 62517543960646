import { Component, Input } from '@angular/core';
import { Footer } from 'src/app/pages/landing-page/landing-page.interface';
import { LandingPageService } from 'src/app/pages/landing-page/landing-page.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() footerTexts: Footer | undefined;
  @Input() isEditingUser: boolean = false;

  constructor(
    private _landingPageService: LandingPageService,
  ) {}

  elementClicked(redirectionUrl: string, index?: number) {
    if (this.isEditingUser) {
      this._landingPageService.editLinkModal$.next({
        linkTextPath: 'footer.element.text',
        linkUrlPath: 'footer.element.redirectionUrl',
        index: index,
      });
    } else {
      window.open(redirectionUrl, '_blank');
    }
  }
}
