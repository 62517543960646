import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { TranslateModule } from '@ngx-translate/core';

// .-- modules --
import { HeaderComponent } from './component/header/header.component';
import { BannerComponent } from './component/banner/banner.component';
import { InfoCardComponent } from './component/info-card/info-card.component';
import { ArticleCardComponent } from './component/article-card/article-card.component';
import { FooterComponent } from './component/footer/footer.component';
import { RegisterBannerComponent } from './component/register-banner/register-banner.component';
import { ModalComponent } from './component/modal/modal.component';
import { SpinnerComponent } from './component/spinner/spinner.component';

// .-- services --
import { VaninAuthorizeService } from 'src/app/api/authorize/vanin-authorize.service';
import { UtilsService } from '@core/services/utils.service';

const modules = [
  HeaderComponent,
  BannerComponent,
  InfoCardComponent,
  ArticleCardComponent,
  FooterComponent,
  RegisterBannerComponent,
  ModalComponent,
  SpinnerComponent,
];

@NgModule({
  declarations: [...modules],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, TranslateModule, PortalModule],
  providers: [VaninAuthorizeService, UtilsService],
  exports: [...modules],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
