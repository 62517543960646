// .-- angular --
import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

// .-- app --
import { environment } from './environments/environment';
import { MainComponent } from './pages/main/main.component';
import {
  FRENCH,
  DUTCH,
  ENGLISH,
  Language,
} from '@core/services/language.service';

export const DUTCH_ROUTE = '';
export const FRENCH_ROUTE = 'fr';
export const ENGLISH_ROUTE = 'en';
export const EDIT_ROUTE = 'edit';

export interface IRouteData {
  isEditingUser?: boolean;
  lang?: Language;
}

export interface CustomRoute extends Route {
  data?: IRouteData;
}

export const routesApp: CustomRoute[] = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: DUTCH_ROUTE,
        data: { lang: DUTCH.code },
        loadChildren: () =>
          import('./pages/landing-page/landing-page.module').then(
            m => m.LandingPageModule
          ),
      },
      {
        path: FRENCH_ROUTE,
        data: { lang: FRENCH.code },
        loadChildren: () =>
          import('./pages/landing-page/landing-page.module').then(
            m => m.LandingPageModule
          ),
      },
      {
        path: ENGLISH_ROUTE,
        data: { lang: ENGLISH.code },
        loadChildren: () =>
          import('./pages/landing-page/landing-page.module').then(
            m => m.LandingPageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routesApp, { scrollPositionRestoration: 'disabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {
    if (!environment.production) {
      routesApp[0].children?.push({
        path: EDIT_ROUTE,
        data: { isEditingUser: true },
        loadChildren: () =>
          import('./pages/landing-page/landing-page.module').then(
            m => m.LandingPageModule
          ),
      });
    }
  }
}
