<div
  *ngIf="bannerTexts"
  class="banner-background tw-text-white tw-flex tw-align-middle tw-justify-center tw-w-full">
  <div class="tw-opacity-100">
    <div class="banner-content tw-max-w-screen-lg tw-py-16 tw-px-4">
      <h2
        class="tw-text-2xl tw-font-bold tw-mb-1"
        [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
        (click)="editText('banner.title')"
        [innerHTML]="bannerTexts.title"></h2>
      <div
        class="tw-text-lg m"
        (click)="editText('banner.description')"
        [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
        [innerHTML]="bannerTexts.description"></div>
      <div class="tw-flex tw-flex-col gap-2 tw-text-lg tw-my-8">
        <div
          *ngFor="let listItem of bannerTexts.list; index as i"
          [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
          (click)="editText('banner.list', i)"
          class="tw-flex tw-flex-row gap-2">
          <div>
            <img
              width="24"
              height="24"
              class="tw-min-w-[24px] tw-min-h-[24px]"
              [ngClass]="{ 'tw-cursor-pointer': isEditingUser }"
              viewBox="0 0 24 24"
              src="/icons/check.svg"
              alt="{{ 'ALT.CHECK_ALT' | translate }}" />
          </div>
          <div [innerHTML]="bannerTexts.list[i]"></div>
        </div>
      </div>
      <div>
        <button
          (click)="signIn()"
          type="button"
          class="btn btn-lg kampus-primary-button tw-text-base"
          [innerHTML]="bannerTexts.buttonText"></button>

        <div class="tw-flex tw-flex-row tw-ml-4 tw-mt-3 gap-2">
          <div
            (click)="register()"
            class="hover:tw-underline tw-cursor-pointer"
            [innerHTML]="bannerTexts.registerText"></div>
          <img
            (click)="register()"
            class="tw-cursor-pointer"
            src="/icons/arrow-right.svg"
            alt="{{ 'ALT.ARROW_ALT' | translate }}" />
        </div>
      </div>
    </div>
  </div>
</div>
