import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private _router: Router) {}

  redirectToExternalPage(externalUrl: string): void {
    window.open(externalUrl, '_blank');
  }

  navigateByURL(url: string): void {
    this._router.navigateByUrl(url);
  }
}
